export default class AudioFile
{
    constructor(name, fileName, duration, filePath, date, type)
    {
        this.Name = name ?? null;
        this.FileName = fileName ?? null;
        this.Duration = duration ?? 0;
        this.FilePath = filePath ?? null;
        this.Date = date ? new Date(date) : null;
        this.Type = type ?? null;
    }

    Name = '';
    FileName = '';
    Duration = 0;
    FilePath = '';
    Date = new Date();
    Type = '';
}