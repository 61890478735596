import { Button } from "@material-ui/core";
import React, { useState } from "react"

export default function CopyToClipboard({ copyValue }) {
    const [isCopied, setIsCopied] = useState(false);

    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
          return await navigator.clipboard.writeText(text);
        } else {
          return document.execCommand('copy', true, text);
        }
    }

    const handleCopyClick = () => {
        copyTextToClipboard(copyValue)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                    console.log(err);
            });
    }

    return (
        <div>
            <input type="text" value={copyValue} title={copyValue} readOnly style={{ backgroundColor: '#555555', color: 'white', padding: '5px', border: 'solid 1px white', borderRadius: '4px' }} />
            <Button style={{ color: 'white' }} onClick={handleCopyClick}>
                <span>{isCopied ? 'Copied' : 'Copy'}</span>
            </Button>
        </div>
    );
};