import React, { useState } from "react";

import { Redirect, Route, Switch } from "react-router-dom";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import AboutPage from "./components/About";
import AudioFilesList from "./components/AudioFilesList";
import { drawerHeight } from "./components/AudioPlayer";

const useStyles = makeStyles((theme) => ({
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create(["margin", "height"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginBottom: -drawerHeight,
    },
    contentShift: {
        transition: theme.transitions.create(["margin", "height"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginBottom: 0,
    },
}));

export default function MainContent({ errorMsg, Api, setTitle, playerOpen, canDownload, setAudio, columns, rows, setSort, sort, setNameFilter, nameFilter, setPlayerState, playerState, currentAudio }) {
    const classes = useStyles();

    return (
        <main
            className={clsx(classes.content, {
                [classes.contentShift]: playerOpen
            })}
        >
            <div className={classes.drawerHeader} />
            <div
                style={{
                    height: window.innerHeight * (playerOpen ? 0.6 : 0.8),
                    marginTop: "auto",
                    marginBottom: "auto",
                }}
            >
                <Switch>
                    <Route path={["/home/index", "/home", "/"]}>
                        {
                            errorMsg ? <>{errorMsg}</> : <></>
                        }
                        <AudioFilesList
                            Api={Api} columns={columns} rows={rows}
                            setAudio={setAudio}
                            canDownload={canDownload}
                            setSort={setSort} sort={sort}
                            setNameFilter={setNameFilter} nameFilter={nameFilter}
                            setPlayerState={setPlayerState} playerState={playerState}
                            playerMenuOpen={playerOpen}
                            currentAudioName={currentAudio.Name}
                            currentIndex={rows.indexOf(currentAudio.AudioFile)}
                        />
                    </Route>
                    <Route path={["/about/index", "/about"]}>
                        <AboutPage />
                    </Route>
                    <Redirect exact strict from="/" to="/" />
                </Switch>
            </div>
        </main>
    );
};