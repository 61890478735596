import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { TablePagination, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TextField, Button } from "@material-ui/core";
import { drawerHeight } from "./AudioPlayer";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        marginBottom: 200,
        minWidth: "80%",
        border: 'solid 1px',
        color: 'white',
    },
}));

export default function AudioFilesList({ Api, setAudio, columns, rows = [], setSort, sort, setNameFilter, nameFilter, currentAudioName, currentIndex = 0, setPlayerState, playerState, playerMenuOpen, canDownload }) {
    const classes = useStyles();

    const defaultRowsPerPage = 10;
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const [page, setPage] = useState(0);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const [playerOpen, setPlayerOpen] = useState(playerMenuOpen);

    useEffect(() => {
        setPlayerOpen(playerMenuOpen);
    }, [playerMenuOpen]);

    useEffect(() => {
        const currentPage = Math.round((currentIndex / defaultRowsPerPage) - 0.5);

        if (currentPage > -1) {
            setPage(currentPage);
        }
    }, [currentIndex]);

    return (
        <div>
            <TableContainer className={classes.tableContainer} style={{ height: window.innerHeight * (playerOpen ? 0.75 : 0.85) }}>
                <div style={{ borderBottom: 'solid 1px white', display: 'flex', justifyContent: 'flex-start', margin: 'auto' }}>
                    <div style={{ display: 'inline-block', marginTop: 'auto', marginBottom: 'auto', width: '50%', overflow: 'hidden', wordWrap: 'revert', }}>
                        <TextField
                            variant="outlined"
                            InputProps={{
                                style: {
                                    marginLeft: '10px',
                                    color: 'white',
                                    border: 'solid 1px white',
                                    marginTop: '5px',
                                    marginBottom: '5px',
                                    overflow: 'hidden',
                                    wordWrap: 'revert',
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    overflow: 'hidden',
                                    wordWrap: 'revert',
                                    marginLeft: '5%',
                                    color: 'white',
                                    marginTop: '5px',
                                    marginBottom: '5px',
                                },
                            }}
                            value={nameFilter}
                            label="Search by name"
                            type="text"
                            onChange={(e) => {
                                setNameFilter(e.target.value);
                            }}
                        />
                    </div>
                    <div style={{ display: 'inline-block', marginTop: 'auto', marginBottom: 'auto', width: '50%', margin: '5px' }}>
                        <div style={{ display: 'inline-block' }}>
                            Filtered by:
                        </div>
                        <Button variant="outlined" style={{ color: 'white', display: 'inline-block', margin: '2px', border: 'solid 1px white', width: '40%', overflow: 'hidden', wordWrap: 'revert', }}
                            onClick={() => {
                                setSort(sort === "UploadDate (newest - oldest)" ? "Name" :
                                    sort === "Name" ? "Duration (min - max)" :
                                    sort === "Duration (min - max)" ? "Duration (max - min)" :
                                    sort === "Duration (max - min)" ? "UploadDate (oldest - newest)" :
                                    sort === "UploadDate (oldest - newest)" ? "UploadDate (newest - oldest)" :
                                    "UploadDate (newest - oldest)");
                            }}
                        >
                            {sort}
                        </Button>
                    </div>
                </div>
                <TablePagination
                    color="white"
                    style={{ color: 'white' }}
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Table style={{ overflow: 'auto' }}>
                    <TableHead style={{ color: 'white', backgroundColor: '#323232' }}>
                        <TableRow style={{ backgroundColor: '#323232', color: 'white' }}>
                        {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth, width: column.minWidth, backgroundColor: '#323232', color: 'white', borderTop: 'solid 1px' }}
                            >
                                {column.label}
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ overflow: 'auto', maxHeight: 20 + (playerOpen ? drawerHeight : 60), width: window.innerWidth * 0.8 }}>
                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                        row.index = rows.indexOf(row) + 1;

                        row.options =
                            <Button
                                name="optionsButton"
                                variant="text"
                                style={{ color: 'white', margin: '0.5rem' }}
                                onClick={(e) =>
                                {
                                    row.onContextMenu(e, row);
                                }}
                            >
                                <FontAwesomeIcon name="optionsButton" icon={faEllipsisH} />
                            </Button>;

                        return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}
                                onClick={(e) => {
                                    if (e.target.name === 'optionsButton' || e.target.parentElement?.name === 'optionsButton' ||
                                        e.target.attributes?.name?.value === 'optionsButton' ||
                                        e.target.parentElement?.attributes?.name?.value === 'optionsButton' ||
                                        e.target.name === 'playButton' || e.target.parentElement?.name === 'playButton' ||
                                        e.target.attributes?.name?.value === 'playButton' ||
                                        e.target.parentElement?.attributes?.name?.value === 'playButton')
                                    {
                                        e.stopPropagation(); return;
                                    }
                                    //setAudio(row);
                                }}
                                onDoubleClick={(e) => {
                                    if (e.target.name === 'optionsButton' || e.target.parentElement?.name === 'optionsButton' ||
                                        e.target.attributes?.name?.value === 'optionsButton' ||
                                        e.target.parentElement?.attributes?.name?.value === 'optionsButton' ||
                                        e.target.name === 'playButton' || e.target.parentElement?.name === 'playButton' ||
                                        e.target.attributes?.name?.value === 'playButton' ||
                                        e.target.parentElement?.attributes?.name?.value === 'playButton')
                                    {
                                        e.stopPropagation(); return;
                                    }
                                    setAudio(row);
                                }}
                                onContextMenu={(e) => {
                                    e.preventDefault();

                                    row.onContextMenu(e, row);
                                }}
                            >
                            {columns.map((column) => {
                                const value = row[column.id];

                                return (
                                    <TableCell key={column.id} align={column.align} style={{ color: 'white', backgroundColor: row.name === currentAudioName ? '#222244' : '#222222', minWidth: column.minWidth }}>
                                        {value}
                                    </TableCell>
                                );
                            })}
                            </TableRow>
                        );
                    })}
                    </TableBody>
                </Table>
                <TablePagination
                    color="white"
                    style={{ color: 'white' }}
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </div>
    );
};