import { faVolumeDown, faVolumeMute, faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Slider } from "@material-ui/core";
import React, { useEffect, useState } from "react";

export default function AudioPlayerVolumeSlider({ currentVolume, changeAudioVolume }) {
    const [volume, setVolume] = useState(currentVolume * 100);

    const volumeChange = (value) => {
        setVolume(value);

        changeAudioVolume(value / 100.00);
    };

    return (
        <Grid container direction="row" style={{ display: 'block', margin: 'auto', alignItems: 'center' }}>
            <Grid style={{ display: 'inline-block', margin: 'auto', width: '70%', verticalAlign: 'middle' }}>
                <Slider
                    name="action"
                    style={{
                        color: '#22DDFF',
                        marginRight: '5px',
                        margin: 'auto',
                        verticalAlign: 'middle',
                    }}
                    min={0}
                    max={100}
                    step={1}
                    value={volume}
                    onChange={(e, value) => { volumeChange(value); }}
                />
            </Grid>
            <Grid style={{ display: 'inline-block', margin: 'auto', width: '30%', verticalAlign: 'middle' }}>
                <FontAwesomeIcon style={{ marginLeft: '10px', color: 'white' }} icon={volume === 0 ? faVolumeMute : volume < 50 ? faVolumeDown :  faVolumeUp} />
            </Grid>
        </Grid>
    );
};