import React, { useEffect, useState } from "react";

import { Button, Dialog, DialogContent, DialogTitle, Grid, makeStyles } from "@material-ui/core";
import Playlist from "./Playlist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faTrash } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        marginBottom: '5px',
        minWidth: "80%",
        color: 'white',
    },
}));

export default function PlaylistDialog({ current, playlist, playlistIndex, setPlaylist, recentPlaylist, repeat, open, setOpen, setAudio }) {
    const classes = useStyles();

    const columns = [
        { id: 'index', minWidth: '10%', align: 'left' },
        { id: 'name', minWidth: '50%', align: 'left' },
        { id: 'duration', minWidth: '10%', align: 'right' },
        { id: 'options', minWidth: '30%', align: 'right' },
    ];

    const [recent, setRecent] = useState(false);

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle style={{ backgroundColor: '#222222', color: 'white' }}>Playlist</DialogTitle>
            <DialogContent style={{ backgroundColor: '#222222', color: 'white' }}>
                <Grid container direction="row" >
                    <Grid container direction="row">
                        <Grid container direction="row" style={{ display: 'inline-block', width: '70%' }}>
                            <Button variant="outlined" style={{ backgroundColor: recent ? '#555555' : '#224477', color: recent ? 'white' : '#22DDFF' }} onClick={() => setRecent(false)}>Next Up</Button>
                            <Button variant="outlined" style={{ backgroundColor: recent ? '#224477' : '#555555', color: recent ? '#22DDFF' : 'white' }} onClick={() => setRecent(true)}>Previously Played</Button>
                        </Grid>
                        <Grid container direction="row" style={{ display: 'inline-block', width: '30%', alignItems: 'flex-end' }}>
                            <Button variant="outlined" style={{ backgroundColor: '#555555', color: 'white', marginleft: '10px', height: '100%' }} title="empty the playlist" onClick={() => setPlaylist([])}><FontAwesomeIcon icon={faTrash} /></Button>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" style={{ marginTop: '10px', marginbottom: '5px' }}>
                        <div style={{ width: '100%' }}>
                            Now Playing:<br />
                            {current.Name !== "No audio playing" ?
                                <Grid className={classes.tableContainer}>
                                    <Grid style={{ padding: 8, width: '100%'}}>
                                        <Grid style={{
                                            userSelect: "none",
                                            padding: 16,
                                            margin: `0 0 ${8}px 0`,
                                            border: 'solid 1px',
                                            background: '#222244',
                                        }}>
                                            {columns.map((column) => {
                                                var value = "";

                                                if (column.id === 'index') {
                                                    value = 0;
                                                }
                                                if (column.id === 'name') {
                                                    value = current.Name;
                                                } else if (column.id === 'duration') {
                                                    value = current.Duration;
                                                } else if (column.id === 'options') {
                                                    value =
                                                        <Button
                                                            name="optionsButton"
                                                            variant="text"
                                                            style={{ color: 'white', margin: '0.5rem' }}
                                                        >
                                                            <FontAwesomeIcon name="optionsButton" icon={faEllipsisH} />
                                                        </Button>;
                                                }

                                                return (
                                                    <Grid key={column.id} align={column.align} style={{ color: 'white', backgroundColor: '#222244', width: column.minWidth, display: 'inline-block' }}>
                                                        {value}
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                :
                                <Grid className={classes.tableContainer}>
                                    <Grid style={{ padding: 8, width: '100%'}}>
                                        <Grid style={{
                                            userSelect: "none",
                                            padding: 16,
                                            margin: `0 0 ${8}px 0`,
                                            border: 'solid 1px',
                                            background: '#222244',
                                        }}>
                                            <Grid align={'center'} style={{ color: 'white', backgroundColor: '#222244', width: '100%', display: 'inline-block' }}>
                                                {current.Name}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </div>
                        <div style={{ width: '100%' }}>
                            <Playlist columns={columns} playlist={recent ? [...recentPlaylist].slice(1) : playlist} playlistIndex={playlistIndex} recent={recent} setPlaylist={setPlaylist} setAudio={setAudio} repeat={repeat} />
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}