import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faGhost } from "@fortawesome/free-solid-svg-icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        marginBottom: 200,
        minWidth: "80%",
        border: 'solid 1px',
        color: 'white',
    },
}));

export default function Playlist({ columns, playlist, playlistIndex, setPlaylist, setAudio, repeat, recent }) {
    const classes = useStyles();

    const [rows, setRows] = useState(playlist);

    useEffect(() => {
        var newActivePlaylist = [...playlist];

        if (!recent) {
            newActivePlaylist = newActivePlaylist.slice(playlistIndex + 1);
        }

        if (repeat === 1 && newActivePlaylist.length < 50) {
            let index = 0;
            while (newActivePlaylist.length < 50) {
                var newItem = { ...playlist[index] };
                newItem.occurenceIndex++;
                newActivePlaylist.push(newItem);

                index++;
                if (index >= playlist.length) {
                    index = 0;
                }
            }
        }
        else if (newActivePlaylist.length > 50) {
            while (newActivePlaylist.length > 50) {
                newActivePlaylist.pop();
            }
        }

        setRows(newActivePlaylist);        
    }, [playlistIndex, playlist, repeat]);

    const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
      
        border: isDragging ? 'solid 1px lightgreen' : 'solid 1px',
        backgroundColor: '#222222',
      
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "#224477" : '#222222',
        padding: grid,
        width: '100%'
    });

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    };

    const onDragEnd = (result) => {
        if (!result.destination || result.source.index === result.destination.index) { return; }

        const items = reorder(
            playlist,
            result.source.index + playlistIndex + 1,
            result.destination.index + playlistIndex + 1,
        );

        setPlaylist(items);
    };

    return (
        <Grid>
            <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {rows.length > 0 ? rows.map((row, index) => {
                                    row.index = index + 1;

                                    row.options =
                                        <Button
                                            name="optionsButton"
                                            variant="text"
                                            style={{ color: 'white', margin: '0.5rem' }}
                                            onClick={(e) =>
                                            {
                                                row.onContextMenu(e, row);
                                            }}
                                        >
                                            <FontAwesomeIcon name="optionsButton" icon={faEllipsisH} />
                                        </Button>;

                                    return (
                                        <Draggable draggableId={(row.index - 1).toString()} index={row.index - 1} key={row.index - 1}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                    
                                                    tabIndex={-1}
                                                    onClick={(e) => {
                                                        if (e.target.name === 'optionsButton' || e.target.parentElement?.name === 'optionsButton' ||
                                                            e.target.attributes?.name?.value === 'optionsButton' ||
                                                            e.target.parentElement?.attributes?.name?.value === 'optionsButton')
                                                        {
                                                            e.stopPropagation(); return;
                                                        }
                                                        setAudio(row, true, true);
                                                    }}
                                                    onContextMenu={(e) => {
                                                        e.preventDefault();
        
                                                        row.onContextMenu(e, row);
                                                    }}
                                                >
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <Grid key={column.id} style={{ color: 'white', backgroundColor: '#222222', width: column.minWidth, alignContent: column.align, display: 'inline-block' }}>
                                                                {value}
                                                            </Grid>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                }) :
                                    <div role="checkbox" tabIndex={-1}>
                                        <Grid style={{ color: 'white', backgroundColor: '#222222', minWidth: '100%' }}>
                                            <div style={{ width: '40%', display: 'inline-block', verticalAlign: 'top' }}>
                                                An infinite void...<br />
                                                of emptiness...
                                            </div>
                                            <div style={{ width: '40%', display: 'inline-block' }}>
                                                <FontAwesomeIcon icon={faGhost} size="6x" /><br /><i>(go {recent ? "listen to" : "add"} some music...)</i>
                                            </div>
                                        </Grid>
                                    </div>
                                }
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
            </DragDropContext>
        </Grid>
    );
};