import axios from "axios";
import AudioFile from "../models/AudioFile";

export default class Api
{
    constructor()
    {
        this.ServerUrl = 'https://mymusicapi.sywapps.com';
        this.ApiUrl = this.ServerUrl + '/api';
    }

    async GetAvailableMusic()
    {
        var res = await axios.get(this.ApiUrl + '/Music/Available');

        var files = res.data && res.data.map ? res.data.map(item =>
            new AudioFile(item.Name, item.FileName, item.Duration, item.FilePath, new Date(item.Date), item.Type)) : [];

        return files;
    }

    DownloadAudioFileUrl(fileName)
    {
        return this.ApiUrl + '/Downloader/Download/' + encodeURIComponent(fileName).replace('#', '%23').replace('/', '%2f').replace('+', '%2B');
    }
}