import { Grid, Slider } from "@material-ui/core";
import React, { useEffect, useState } from "react";

export default function AudioPlayerTimeLine({ currentTime, changeAudioTime, audioDuration }) {
    const [timelineTime, setTimelineTime] = useState(currentTime);
    const [timeoutTask, setTimeoutTask] = useState(null);

    useEffect(() => {
        if (timeoutTask === null) {
            setTimelineTime(currentTime);
        }
    }, [currentTime]);

    const timelineChange = (value) => {
        if (timeoutTask !== null) {
            clearTimeout(timeoutTask);
        }

        setTimelineTime(value);

        setTimeoutTask(
            setTimeout(() => {
                changeAudioTime(value);

                setTimeoutTask(null);
            }, 250)
        );
    };

    return (
        <Grid>
            <Slider
                name="action"
                style={{
                    color: '#22DDFF',
                    verticalAlign: 'middle',
                }}
                min={0}
                max={audioDuration}
                step={1}
                value={timelineTime}
                onChange={(e, value) => { timelineChange(value); }}
            />
        </Grid>
    );
};