import React, { useEffect, useRef, useState } from "react";
import "./app.css";

import { makeStyles } from "@material-ui/core/styles";

import { BrowserRouter as Router, Link, useParams } from "react-router-dom";

import { Redirect, Route, Switch } from "react-router-dom";

import { CircularProgress, Box, Grid, Button, IconButton, Typography, Toolbar, CssBaseline, AppBar, Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import clsx from "clsx";

import MainContent from "./MainContent";
import { AudioPlayer, drawerHeight } from "./components/AudioPlayer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleUp, faMusic, faPlay } from "@fortawesome/free-solid-svg-icons";
import { useAPI, ApiProvider } from "./api/api-context";
import PlaylistDialog from "./components/PlaylistDialog";
import CopyToClipboard from "./components/CopyToClipboard";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        height: "100%",
    },
    appBar: {
        backgroundColor: '#222222',
        color: 'white',
        borderBottom: 'solid 1px #22DDFF',
    },
    appBarShift: {
        transition: theme.transitions.create(["margin", "height"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: "none",
    },
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 2,
        padding: theme.spacing(3),
        transition: theme.transitions.create(["margin", "height"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginBottom: -drawerHeight,
    },
    contentShift: {
        transition: theme.transitions.create(["margin", "height"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginBottom: 0,
    },
}));

export default function App() {
    return (
        <>
            <ApiProvider>
                <Router>
                    <Switch>
                        <Route path={["/home/:song?", "/:song?", "/home/", "/"]}>
                            <AppShell />
                        </Route>
                    </Switch>
                </Router>
            </ApiProvider>
        </>
    );
};

function AppShell() {
    const { song } =  useParams();

    const [canDownload, setCanDownload] = useState(false);

    const { Api } = useAPI();
    const [title, setTitle] = useState("SYW Music");
    const classes = useStyles();

    const breakpoint = 800;
    const [mobileScreen, setMobileScreen] = useState(window.innerWidth < breakpoint);
    useEffect(() => {
        window.addEventListener('resize', () => {
            setMobileScreen(window.innerWidth < breakpoint);
        });

        return () => {
            window.removeEventListener('resize', () => {
                setMobileScreen(window.innerWidth < breakpoint);
            });
        };
    });

    const [playerOpen, setPlayerOpen] = useState(true);

    const audioRef = useRef(new Audio());
    
    const [playlistIndex, setPlaylistIndex] = useState(-1);
    const [shuffledPlaylist, setShuffledPlaylist] = useState([]);
    const [playlist, setPlaylist] = useState([]);
    const [recentPlaylist, setRecentPlaylist] = useState([]);

    const [sort, setSort] = useState("UploadDate (newest - oldest)");
    const [nameFilter, setNameFilter] = useState("");

    const [filteredRows, setFilteredRows] = useState([]);

    const columns = [];
    if (!mobileScreen) columns.push({ id: 'index', label: '', align: 'left', minWidth: '5%' });
    columns.push({ id: 'play', label: '', align: 'left', minWidth: '5%' });
    if (!mobileScreen) columns.push({ id: 'datestr', label: 'Date', align: 'left', minWidth: '15%' });
    columns.push({ id: 'name', label: 'Name', align: 'left', minWidth: '40%' });
    if (!mobileScreen) columns.push({ id: 'playing', label: '', align: 'right', minWidth: '10%' });
    columns.push({ id: 'duration', label: 'Duration', align: 'center', minWidth: '15%' });
    columns.push({ id: 'options', label: '', align: 'right', minWidth: '10%' });

    const [rows, setRows] = useState([]);

    const [loading, setLoading] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(10);
    const [currentTime, setCurrentTime] = useState(0);
    const [currentVolume, setCurrentVolume] = useState(1);
    const [audioDuration, setAudioDuration] = useState(0);
    const [audioPlayerState, setAudioPlayerState] = useState("Stopped");
    const [currentAudio, setCurrentAudio] = useState({ Name: "No audio playing", FilePath: '', Duration: '', AudioFile: {} });
    const [inPlayPromise, setInPlayPromise] = useState(false);

    const [shuffle, setShuffle] = useState(false);
    const [repeat, setRepeatVal] = useState(0);
    const setRepeat = (value) => {
        setRepeatVal(value > 2 ? 0 : value);
    };
    const [autoplay, setAutoplay] = useState(false);

    const [playlistOpen, setPlaylistOpen] = useState(false);
    const [optionMenuOpen, setOptionMenuOpen] = useState(false);
    const [optionMenuData, setOptionMenuData] = useState({});

    const [errorMsg, setError] = useState(null);

    const newAudioFile = (audioFile, playImmediately = false) => {
        audioRef.current.pause();
        audioRef.current.remove();

        let newAudio = new Audio(encodeURI(Api.ServerUrl + '/music/' + audioFile.fileName).replace('#', '%23'));
        newAudio.volume = currentVolume;

        setLoading(true);
        audioRef.current = newAudio;
        setCurrentAudio({ Name: audioFile.name, FilePath: audioFile.filePath, Duration: audioFile.duration, AudioFile: audioFile });
        setAudioPlayerState("");
        setCurrentTime(0);

        audioRef.current.onloadeddata = (e) =>
        {
            console.log(`Audio file loaded.`);
            setAudioDuration(audioRef.current.duration);

            if (playImmediately) {
                setAudioPlayerState("Playing");
            } else {
                setAudioPlayerState("Stopped");
            }

            setLoading(false);
        };
    };

    const selectAudioFile = (audioFile, playImmediately = true, fromPlaylist = true) => {
        if (fromPlaylist) {
            const replaceCurrent = currentTime > 0;

            setPlaylist(playlist => {
                var newPlaylist = [...playlist];
                if (replaceCurrent) {
                    newPlaylist[0] = audioFile;
                } else if (currentAudio.Name != 'No audio playing') {
                    newPlaylist.splice(0, 0, audioFile);
                }
                return newPlaylist;
            });

            setShuffledPlaylist(shuffledPlaylist => {
                var newShuffledPlaylist = [...shuffledPlaylist];
                if (replaceCurrent) {
                    newShuffledPlaylist[0] = audioFile;
                } else if (currentAudio.Name != 'No audio playing') {
                    newShuffledPlaylist.splice(0, 0, audioFile);
                }
                return newShuffledPlaylist;
            });
        }
        else {
            var list = [...filteredRows];

            setPlaylist(list);

            if (shuffle) {
                setShuffledPlaylist(shuffleArr(list));
            }

            let index = list.indexOf(audioFile);

            setPlaylistIndex(index);
        }

        newAudioFile(audioFile, playImmediately);
    };

    const changeAudioTime = (newTime) => {
        audioRef.current.currentTime = newTime;
        setCurrentTime(newTime);
    };

    const changeAudioVolume = (newVolume) => {
        audioRef.current.volume = newVolume;
        setCurrentVolume(newVolume);
    };

    const PreviousAudioFile = (playImmediately = false) => {
        if (repeat === 2 && currentAudio.AudioFile) {
            newAudioFile(
                currentAudio.AudioFile,
                true
            );
            return;
        }

        var upcomingList = [...(shuffle ? shuffledPlaylist : playlist)];

        var recentList = [...recentPlaylist].slice(1);

        var nextRow = recentList[0];
        if (!nextRow) {
            let prevIndex = playlistIndex <= 0 ? repeat === 1 ? upcomingList.length - 1 : playlistIndex : playlistIndex - 1;

            nextRow = upcomingList[prevIndex];

            setPlaylistIndex(prevIndex);
        }
        else {
            recentList.reverse();
            recentList.pop();
            recentList.reverse();
            setRecentPlaylist(recentList);

            setPlaylistIndex(playlistIndex - 1);
        }

        if (nextRow) {
            console.log(`Previous song: '${nextRow.name}'`);

            newAudioFile(
                nextRow,
                playImmediately
            );
        }
    };

    const NextAudioFile = (playImmediately = false) => {
        if ((shuffle ? shuffledPlaylist : playlist).length === 0 && repeat == 0) { return; }

        if (currentAudio.AudioFile && (repeat === 2 || (repeat == 1 && (shuffle ? shuffledPlaylist : playlist).length === 0))) {
            newAudioFile(
                currentAudio.AudioFile,
                true
            );
            return;
        }

        var upcomingList = [...(shuffle ? shuffledPlaylist : playlist)];
        
        var nextRow = upcomingList[playlistIndex + 1];
        if (!nextRow) {
            let nextIndex = playlistIndex >= upcomingList.length - 1 ? repeat === 1 ? 0 : playlistIndex : playlistIndex + 1;

            if (nextIndex === upcomingList.length) { return; }

            nextRow = upcomingList[nextIndex];

            setPlaylistIndex(nextIndex);
        }
        else {
            setPlaylistIndex(playlistIndex + 1);
        }

        console.log(`Next song: '${nextRow.name}'`);

        newAudioFile(
            nextRow,
            playImmediately
        );
    };

    useEffect(() =>
    {
        if (currentAudio.Name === "No audio playing") { return; }

        if (audioPlayerState === "Playing")
        {
            console.log(`Playing`);
            setInPlayPromise(true);
            setLoading(true);

            const currentLoad = audioRef.current.preload;

            var interval = setInterval(() => {
                if (audioRef.current.preload !== currentLoad) {
                    setInPlayPromise(false);
                    setLoading(false);

                    var current = (shuffle ? shuffledPlaylist : playlist)[playlistIndex];
                    var recentList = [...recentPlaylist];
                    if (current && current.id !== recentPlaylist[0]?.id) {
                        recentList = [current, ...recentPlaylist];
                        if (recentList.length > 50) { recentList.pop(); }
                    }

                    setRecentPlaylist(recentList);
                }
            }, 500);

            audioRef.current.play().then(() => {
                clearInterval(interval);

                setInPlayPromise(false);
                setLoading(false);

                var current = (shuffle ? shuffledPlaylist : playlist)[playlistIndex];
                var recentList = [...recentPlaylist];
                if (current && current.id !== recentPlaylist[0]?.id) {
                    recentList = [current, ...recentPlaylist];
                    if (recentList.length > 50) { recentList.pop(); }
                }

                setRecentPlaylist(recentList);
            });
        }
        else if (audioPlayerState === "Paused")
        {
            console.log(`Paused`);
            if (!audioRef.current.paused) {
                audioRef.current.pause();
            }
        }
        else if (audioPlayerState === "Stopped")
        {
            console.log(`Stopped`);
            if (!audioRef.current.paused) {
                audioRef.current.pause();
            }
            audioRef.current.currentTime = 0;
            setCurrentTime(0);
        }
    }, [audioRef.current, audioPlayerState, currentAudio.Name]);

    useEffect(() => {
        Api.GetAvailableMusic().then((audioFiles = []) =>
        {
            var rowData = audioFiles.map((audioFile, index) => {
                let duration = "";
                let mins = parseInt((audioFile.Duration + 0.5) / 60);
                duration += mins < 10 ? "0" + mins : mins;

                duration += ':';
                
                let secs = parseInt((audioFile.Duration + 0.5) % 60);
                duration += secs < 10 ? "0" + secs : secs;

                let date = `${audioFile.Date.getDate()  < 10 ? "0" + audioFile.Date.getDate() : audioFile.Date.getDate()}-${audioFile.Date.getMonth() + 1 < 10 ? "0" + (audioFile.Date.getMonth() + 1): audioFile.Date.getMonth() + 1}-${audioFile.Date.getFullYear()} ` +
                    `${audioFile.Date.getHours() < 10 ? "0" + audioFile.Date.getHours() : audioFile.Date.getHours()}:${audioFile.Date.getMinutes() < 10 ? "0" + audioFile.Date.getMinutes() : audioFile.Date.getMinutes()}`;

                var row = {
                    id: audioFile.FileName,
                    play:
                    <Button
                        name="playButton"
                        variant="text"
                        style={{ color: 'white', margin: '0.5rem' }}
                        onClick={(e) =>
                        {
                            selectAudioFile(row, true);
                        }}
                    >
                        <FontAwesomeIcon name="playButton" icon={faPlay} />
                    </Button>,
                    date: audioFile.Date,
                    datestr: date,
                    name: audioFile.Name,
                    fileName: audioFile.FileName,
                    filePath: audioFile.FilePath,
                    duration: duration,
                    durationInSeconds: audioFile.Duration,
                    occurenceIndex: 1,
                    onContextMenu: (e, row) => {
                        setOptionMenuData({ Name: row.name, Occurence: row.occurenceIndex });
                        setOptionMenuOpen(true);
                    },
                };

                return row;
            });

            setRows(rowData);
            setPlaylist(rowData);

            if (song) {
                let index = rowData.findIndex(x => x.name === song.replace('%23', '#').replace('%2f', '/').replace('%2B', '+'));
                if (index > -1) {
                    newAudioFile(rowData[index], false);
                    setPlaylistIndex(index);
                } else {
                    setError("Sorry, this song does not exists!");
                }
            }
        });
    }, [Api, sort]);

    const shuffleArr = (array) => {
        var currentIndex = array.length, randomIndex;
      
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
      
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
      
            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]
            ];
        }
      
        return array;
    }

    useEffect(() => {
        if (shuffle) {
            var shuffled = shuffleArr([...playlist]);

            setShuffledPlaylist(shuffled);
        }
    }, [shuffle, playlist]);

    useEffect(() => {
        const onEnded = (e) => {
            NextAudioFile(autoplay);
            if(!autoplay) { setAudioPlayerState("Stopped"); }
        };

        audioRef.current.addEventListener('ended', onEnded);

        audioRef.current.addEventListener('pause', (e) => {
            if (audioPlayerState !== 'Paused') {
                setAudioPlayerState('Paused');
            }
        });

        audioRef.current.addEventListener('play', (e) => {
            if (audioPlayerState !== 'Playing') {
                setAudioPlayerState('Playing');
            }
        });

        return () => {
            audioRef.current.removeEventListener('ended', onEnded);

            audioRef.current.removeEventListener('pause', (e) => {
                if (audioPlayerState !== 'Paused') {
                    setAudioPlayerState('Paused');
                }
            });
    
            audioRef.current.removeEventListener('play', (e) => {
                if (audioPlayerState !== 'Playing') {
                    setAudioPlayerState('Playing');
                }
            });
        };
    }, [audioRef.current, audioPlayerState, autoplay, repeat, playlist, recentPlaylist]);

    useEffect(() => {
        audioRef.current.addEventListener('timeupdate', (e) => {
            setCurrentTime(audioRef.current.currentTime);
        });

        return () => {
            audioRef.current.removeEventListener('timeupdate', (e) => {
                setCurrentTime(audioRef.current.currentTime);
            });
        };
    }, [audioRef.current]);

    useEffect(() => {
        audioRef.current.addEventListener('progress', (e) => {
            console.log('loading: ', e.total);
            setLoadingProgress(e.total);
        });

        return () => {
            audioRef.current.removeEventListener('progress', (e) => {
                console.log('loading: ', e.total);
                setLoadingProgress(e.total);
            });
        }
    }, [audioRef.current]);

    useEffect(() => {
        const filterResults = (data = []) => {
            var results = nameFilter.length > 0 ? data.filter(x => x.name.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1) : data;
    
            return results.sort((a, b) => {
                if (sort === "UploadDate (newest - oldest)") {
                    if (a.date > b.date) return -1;
                    else if (a.date < b.date) return 1;
                    else return 0;
                }
                else if (sort === "UploadDate (oldest - newest)") {
                    if (a.date < b.date) return -1;
                    else if (a.date > b.date) return 1;
                    else return 0;
                }
                else if (sort == "Name") {
                    if (a.name < b.name) return -1;
                    else if (a.name > b.name) return 1;
                    else return 0;
                }
                else if (sort == "Duration (min - max)") {
                    if (a.durationInSeconds < b.durationInSeconds) return -1;
                    else if (a.durationInSeconds > b.durationInSeconds) return 1;
                    else return 0;
                }
                else if (sort == "Duration (max - min)") {
                    if (a.durationInSeconds > b.durationInSeconds) return -1;
                    else if (a.durationInSeconds < b.durationInSeconds) return 1;
                    else return 0;
                }
            });
        };

        setFilteredRows(
            filterResults(rows)
        );
    }, [nameFilter, rows, sort]);

    return (
        <>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: playerOpen,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open player"
                        onClick={() => setPlayerOpen(!playerOpen)}
                        edge="start"
                        className={clsx(classes.menuButton)}
                    >
                        <FontAwesomeIcon icon={faMusic} />
                    </IconButton>
                    <Grid>
                        <Link style={{ textDecoration: 'none' }} to="/playlists">
                            <Button style={{ color: 'white' }}>
                                <Typography variant="h6" noWrap className={classes.title}>{title}</Typography>
                            </Button>
                        </Link>
                    </Grid>
                    <Grid>
                        <Button style={{ color: 'white' }} onClick={() => setPlaylistOpen(!playlistOpen)}>Playlist</Button>
                    </Grid>
                </Toolbar>
            </AppBar>
            <AudioPlayer
                Api={Api}
                setPlaylistOpen={setPlaylistOpen}
                playlistOpen={playlistOpen}
                loading={loading}
                loadingProgress={loadingProgress}
                CurrentAudio={currentAudio}
                changeAudioVolume={changeAudioVolume}
                currentVolume={currentVolume}
                changeAudioTime={changeAudioTime}
                currentTime={currentTime}
                audioDuration={audioDuration}
                audioPlayerState={audioPlayerState}
                setAudioPlayerState={setAudioPlayerState}
                audio={audioRef.current}
                open={playerOpen}
                onOpen={setPlayerOpen}
                shuffle={shuffle}
                setShuffle={setShuffle}
                repeat={repeat}
                setRepeat={setRepeat}
                autoplay={autoplay}
                setAutoplay={setAutoplay}
                PreviousAudioFile={PreviousAudioFile}
                NextAudioFile={NextAudioFile}
                inPlayPromis={inPlayPromise}
                canDownload={canDownload}
            />
            <MainContent
                Error={errorMsg}
                Api={Api} setTitle={setTitle}
                playerOpen={playerOpen} onPlayerOpen={setPlayerOpen}
                setAudio={selectAudioFile}
                columns={columns} rows={filteredRows}
                setSort={setSort} sort={sort}
                setNameFilter={setNameFilter} nameFilter={nameFilter}
                setPlayerState={setAudioPlayerState}
                playerState={audioPlayerState}
                repeat={repeat}
                currentAudio={currentAudio}
                canDownload={canDownload}
            />
            <PlaylistDialog
                current={currentAudio} playlistIndex={playlistIndex} playlist={repeat === 2 ? [ currentAudio.AudioFile, currentAudio.AudioFile ] : shuffle ? shuffledPlaylist : playlist}
                setPlaylist={shuffle ? setShuffledPlaylist : setPlaylist}
                setAudio={selectAudioFile}
                recentPlaylist={recentPlaylist} repeat={repeat} open={playlistOpen}
                setOpen={setPlaylistOpen}
            />

            <Dialog open={optionMenuOpen} onClose={() => setOptionMenuOpen(false)}>
                <DialogTitle style={{ backgroundColor: '#222222', color: 'white' }}>Options: {optionMenuData.Name}</DialogTitle>
                <DialogContent style={{ backgroundColor: '#222222', color: 'white' }}>
                    <Grid container direction="row">
                        <Grid container direction="row" style={{ marginBottom: '5px' }}>
                            <Button variant="outlined" style={{ backgroundColor: '#555555', color: 'white' }}
                                onClick={() => {
                                    var newAudio = { ...(rows.find(x => x.name === optionMenuData.Name && x.occurenceIndex === optionMenuData.Occurence)) };
                                    var occurences = (shuffle ? shuffledPlaylist : playlist).filter(x => x.id === newAudio.id);
                                    let index = 1;
                                    newAudio.occurenceIndex = index;
                                    if (occurences.length > 0) {
                                        for (var occurence of occurences) {
                                            occurence.occurenceIndex = ++index;
                                        }
                                    }
                                    
                                    var newPlaylist = [...(shuffle ? shuffledPlaylist : playlist)];
                                    newPlaylist.splice(playlistIndex + 1, 0, newAudio);
                                    shuffle ? setShuffledPlaylist(newPlaylist) : setPlaylist(newPlaylist);

                                    setOptionMenuOpen(false);
                                }}
                            >As Next</Button>
                        </Grid>
                        <Grid container direction="row" style={{ marginBottom: '5px' }}>
                            <Button variant="outlined" style={{ backgroundColor: '#555555', color: 'white' }}
                                onClick={() => {
                                    var newAudio = { ...(rows.find(x => x.name === optionMenuData.Name && x.occurenceIndex === optionMenuData.Occurence)) };
                                    var occurences = (shuffle ? shuffledPlaylist : playlist).filter(x => x.id === newAudio.id);
                                    let index = 1;
                                    if (occurences.length > 0) {
                                        for (var occurence of occurences) {
                                            occurence.occurenceIndex = index++;
                                        }
                                    }
                                    newAudio.occurenceIndex = index;

                                    var newPlaylist = [...(shuffle ? shuffledPlaylist : playlist)];
                                    newPlaylist.push(newAudio);
                                    shuffle ? setShuffledPlaylist(newPlaylist) : setPlaylist(newPlaylist);

                                    setOptionMenuOpen(false);
                                }}
                            >Add to Playlist</Button>
                        </Grid>
                        <Grid container direction="row" style={{ marginBottom: '5px' }}>
                            <Button variant="outlined" style={{ backgroundColor: '#555555', color: 'white' }}
                                onClick={() => {
                                    var newPlaylist = [...(shuffle ? shuffledPlaylist : playlist)];

                                    var index = newPlaylist.findIndex(x => x.name === optionMenuData.Name && x.occurenceIndex === optionMenuData.Occurence);

                                    newPlaylist.splice(index, 1);
                                    shuffle ? setShuffledPlaylist(newPlaylist) : setPlaylist(newPlaylist);

                                    setOptionMenuOpen(false);
                                }}
                            >Remove from Playlist</Button>
                        </Grid>
                        <Grid container direction="row" style={{ marginBottom: '5px' }}>
                            <Button variant="outlined" style={{ backgroundColor: '#555555', color: 'white' }}
                                onClick={() => {
                                    var row = rows.find(x => x.name === optionMenuData.Name);

                                    let url = Api.DownloadAudioFileUrl(row.fileName);

                                    window.open(url);
                                }}
                            >Download</Button>
                        </Grid>
                        <Grid container direction="row" style={{ marginBottom: '5px' }}>
                            <div style={{ backgroundColor: '#555555', color: 'white', borderRadius: '4px', padding: '10px' }}>
                                <div style={{ width: '100%', textTransform: 'uppercase' }}>
                                    Share
                                </div>
                                <div style={{ width: '100%' }}>
                                    <CopyToClipboard copyValue={`${window.location.protocol}//${window.location.host}/${encodeURIComponent(optionMenuData.Name).replace('#', '%23').replace('/', '%2f').replace('+', '%2B')}`} />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            
            <footer style={{ width: '100%', height: 50, position: 'fixed', bottom: 0, backgroundColor: '#222222', borderTop: 'solid 1px #22DDFF' }}
                onClick={() => setPlayerOpen(true)} onMouseEnter={() => { window.document.body.style.cursor = 'pointer'; }} onMouseLeave={() => { window.document.body.style.cursor = 'default'; }}>
                <div style={{ display: 'flex', flex: 1, margin: 'auto', justifyContent: 'flex-center', alignItems: 'center' }}>
                    <IconButton style={{ color: 'white' }}>
                        <FontAwesomeIcon icon={faArrowAltCircleUp} />
                    </IconButton>
                    {loading ?
                        <Box position="relative" display="inline-flex">
                            <CircularProgress variant="indeterminate" />
                            <Box
                                top={0}
                                left={0}
                                bottom={0}
                                right={0}
                                position="absolute"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Typography variant="caption" component="div"  style={{ color: 'white' }}>
                                    {`${Math.round(
                                        loadingProgress
                                    )}%`}
                                </Typography>
                            </Box>
                        </Box>
                    :
                        <Typography variant="subtitle2" style={{ color: 'white' }}>{currentAudio.Name} - {audioPlayerState}</Typography>
                    }
                </div>
            </footer>
        </>
    );
};