import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Drawer, IconButton, Button, Typography, CircularProgress, Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleDown, faArrowAltCircleUp, faBackward, faForward, faList, faPauseCircle, faPlay, faPlayCircle, faRandom, faRecycle, faRedo, faStopCircle, faSync } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import AudioPlayerTimeLine from "./AudioPlayerTimeLine";
import AudioPlayerVolumeSlider from "./AudioPlayerVolumeSlider";

import { HAS_MEDIA_SESSION, useMediaSession } from '@mebtte/react-media-session';

export const drawerHeight = 200;

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        height: drawerHeight,
        backgroundColor: '#222222',
        borderTop: 'solid 1px #22DDFF',
    },
    player: {
        display: 'flex',
        justifyContent: 'flex-center',
        alignItems: "center",
        flex: 1,
        width: '100%',
        paddingLeft: '15px',
        paddingRight: '15px',
    },
}));

export function AudioPlayer({ Api, loading, loadingProgress, setPlaylistOpen, playlistOpen, open, onOpen, setShuffle, shuffle, setRepeat, repeat, setAutoplay, autoplay, canDownload, CurrentAudio, audio, changeAudioVolume, currentVolume, changeAudioTime, currentTime, audioDuration, setAudioPlayerState, audioPlayerState, PreviousAudioFile, NextAudioFile, inPlayPromise }) {
    const classes = useStyles();

    const history = useHistory();

    if (HAS_MEDIA_SESSION) {
        useMediaSession({
            title: CurrentAudio.Name,
            artist: 'ShutYourWaffle',
            album: 'SYW Music',
            artwork: [
                {
                    src: 'SYW_Logo_icon_512.png',
                    sizes: '256x256,384x384,512x512',
                    type: 'image/png',
                },
                {
                    src: 'SYW_Logo_icon_192.png',
                    sizes: '96x96,128x128,192x192',
                    type: 'image/png',
                }
            ],
            onPlay: () => {
                audio.play();
            },
            onPause: () => {
                audio.pause();
            },
            onSeekBackward: () => {
                const newTime = audio.currentTime - 5;
                audio.currentTime = newTime < 0.0 ? 0 : newTime;
            },
            onSeekForward: () => {
                const newTime = audio.currentTime + 5;
                audio.currentTime = newTime > audio.duration ? audio.duration : newTime;
            },
            onPreviousTrack: () => {
                PreviousAudioFile(true);
            },
            onNextTrack: () => {
                NextAudioFile(true);
            },
        });
    }

    const [playPauseIcon, setPlayPauseIcon] = useState();

    useEffect(() => {
        setPlayPauseIcon(audioPlayerState === "Paused" || audioPlayerState == "Stopped" ? faPlayCircle : faPauseCircle);
    }, [audioPlayerState]);

    const IntToTimer = (seconds) => {
        let str = "";
        let mins = parseInt((seconds + 0.5) / 60);
        str += mins;

        str += ':';
        
        let secs = parseInt((seconds + 0.5) % 60);
        str += secs < 10 ? "0" + secs : secs;

        return str;
    };

    let duration = IntToTimer(audioDuration);
    let current = IntToTimer(currentTime);

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="bottom"
            open={open}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <IconButton style={{ color: 'white' }} onClick={() => onOpen(false)}>
                <FontAwesomeIcon icon={faArrowAltCircleDown} />
            </IconButton>
            <div className={classes.player} onDoubleClick={(e) => {
                    if (e.target.name === 'action' || e.target.parentElement?.name === 'action' ||
                        e.target.attributes?.name?.value === 'action' ||
                        e.target.parentElement?.attributes?.name?.value === 'action')
                    {
                        e.stopPropagation(); return;
                    }
                    onOpen(false);
                }}>
                <div
                    style={{ display: 'flex', justifyContent: 'flex-center', margin: 'auto' }}
                >
                    <div>
                        {loading ?
                            <Grid container direction="row" style={{ display: 'block', margin: 'auto' }}>
                                <Box position="relative" display="inline-flex">
                                    <CircularProgress variant="indeterminate" />
                                    <Box
                                        top={0}
                                        left={0}
                                        bottom={0}
                                        right={0}
                                        position="absolute"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Typography variant="caption" component="div" style={{ color: 'white' }}>
                                            {`${Math.round(loadingProgress)}%`}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        :
                            <>
                            <Grid container direction="row" style={{ display: 'block', margin: 'auto', alignItems: 'center' }}>
                                <Grid style={{ display: 'inline-block', margin: 'auto', width: '60%' }}>
                                    <Typography name="action" style={{ margin: 'auto', color: 'white' }} variant="subtitle1">{CurrentAudio.Name}</Typography>
                                </Grid>
                                <Grid style={{ display: 'inline-block', margin: 'auto', width: '40%' }}>
                                    <AudioPlayerVolumeSlider currentVolume={currentVolume} changeAudioVolume={changeAudioVolume} />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" style={{ display: 'block', margin: 'auto' }}>
                                <Grid style={{ display: 'inline-block', margin: 'auto', overflowX: 'hidden' }}>
                                    <Grid style={{ display: 'inline-block', margin: 'auto' }}>
                                        <Button
                                            style={{ color: 'white', backgroundColor: '#555555', margin: '0.5rem' }} variant="outlined" name="action"
                                            onClick={() =>
                                            {
                                                console.log(`Loading previous song.`);

                                                PreviousAudioFile(audioPlayerState === "Playing");
                                            }}
                                        >
                                            <FontAwesomeIcon name="action" icon={faBackward} />
                                        </Button>
                                    </Grid>
                                    <Grid style={{ display: 'inline-block', margin: 'auto' }}>
                                        <Button
                                            style={{ color: 'white', backgroundColor: '#555555', margin: '0.5rem' }} variant="outlined" name="action"
                                            onClick={(e) =>
                                            {
                                                if (CurrentAudio.Name !== "No audio playing" && (audioPlayerState === "Paused" || audioPlayerState === "Stopped")) {
                                                    setAudioPlayerState("Playing");
                                                }
                                                else if (audioPlayerState === "Playing" && !inPlayPromise) {
                                                    setAudioPlayerState("Paused");
                                                }
                                            }}
                                        >
                                            <FontAwesomeIcon name="action" icon={playPauseIcon} />
                                        </Button>
                                    </Grid>
                                    <Grid style={{ display: 'inline-block', margin: 'auto' }}>
                                        <Button
                                            style={{ color: 'white', backgroundColor: '#555555', margin: '0.5rem' }} variant="outlined" name="action"
                                            onClick={(e) =>
                                            {
                                                console.log(`Loading next song.`);

                                                NextAudioFile(audioPlayerState === "Playing");
                                            }}
                                        >
                                            <FontAwesomeIcon name="action" icon={faForward} />
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid style={{ display: 'inline-block', margin: 'auto', overflowX: 'hidden' }}>
                                    <Grid style={{ display: 'inline-block', margin: 'auto' }}>
                                        <Button
                                            style={{ color: 'white', backgroundColor: '#555555', margin: '0.5rem' }} variant="outlined" name="action"
                                            onClick={(e) =>
                                            {
                                                setShuffle(!shuffle);
                                            }}
                                        >
                                            <FontAwesomeIcon name="action" style={{ color: shuffle ? '#22DDFF' : 'white' }} icon={faRandom} />
                                        </Button>
                                    </Grid>
                                    <Grid style={{ display: 'inline-block', margin: 'auto' }}>
                                        <Button
                                            style={{ color: repeat >= 1 ? '#22DDFF' : 'white', backgroundColor: '#555555', margin: '0.5rem' }} variant="outlined" name="action"
                                            onClick={(e) =>
                                            {
                                                setRepeat(++repeat);
                                            }}
                                        >
                                            <FontAwesomeIcon name="action" style={{ color: repeat >= 1 ? '#22DDFF' : 'white' }} icon={faSync} /><Grid style={{ fontSize: "xx-small", lineHeight: '5px' }}>{repeat === 2 ? "-1" : ""}</Grid>
                                        </Button>
                                    </Grid>
                                    <Grid style={{ display: 'inline-block', margin: 'auto' }}>
                                        <Button
                                            style={{ color: 'white', backgroundColor: '#555555', margin: '0.5rem' }} variant="outlined" name="action"
                                            onClick={(e) =>
                                            {
                                                setAutoplay(!autoplay);
                                            }}
                                        >
                                            <FontAwesomeIcon name="action" style={{ marginLeft: '5px', color: autoplay ? '#22DDFF' : 'white' }} icon={faPlay} />
                                            <FontAwesomeIcon name="action" style={{ marginLeft: '5px', color: autoplay ? '#22DDFF' : 'white' }} icon={faRedo} />
                                        </Button>
                                    </Grid>
                                    <Grid style={{ display: 'inline-block', margin: 'auto' }}>
                                        <Button
                                            style={{ color: 'white', backgroundColor: '#555555', margin: '0.5rem' }} variant="outlined" name="action"
                                            onClick={(e) =>
                                            {
                                                setPlaylistOpen(!playlistOpen);
                                            }}
                                        >
                                            <FontAwesomeIcon name="action" style={{ color: playlistOpen ? '#22DDFF' : 'white' }} icon={faList} />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" style={{ display: 'block', margin: 'auto' }}>
                                <Grid style={{ display: 'inline-block', width: '70%', marginRight: '5%', marginTop: 'auto', marginBottom: 'auto' }}>
                                    <AudioPlayerTimeLine audioDuration={audioDuration} changeAudioTime={changeAudioTime} currentTime={currentTime} />
                                </Grid>
                                <Grid style={{ display: 'inline-block', margin: 'auto', minWidth: '25%', maxWidth: '25%' }}>
                                    <Typography name="action" variant="subtitle2" style={{ color: 'white' }}>{current ?? '0:00'} / {duration ?? '0:00'}</Typography>
                                </Grid>
                            </Grid>
                            </>
                        }
                    </div>
                </div>
            </div>
        </Drawer>
    );
};