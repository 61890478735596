import { faBackward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { drawerHeight } from "./AudioPlayer";

export default function AboutPage({  }) {
    return (
        <Grid style={{ marginBottom: drawerHeight }}>
            <Typography style={{ color: 'white' }}>Listen to my favorite songs on spotify:</Typography>
            <iframe src="https://open.spotify.com/embed/playlist/4SpuBwdXL4tNiRfttBgtQv?theme=0" width="100%" height="380" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
            <Link to="/home/index" style={{ textDecoration: 'none' }}>
                <Button variant="outlined" style={{ marginTop: '10px', color: 'white' }}>
                    <FontAwesomeIcon icon={faBackward} style={{ marginRight: '5px' }} /> Back to SYW Music
                </Button>
            </Link>
        </Grid>
    );
};